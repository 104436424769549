import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgLogo from "../images/logo-white-alpha-large.png"
import imgCoach from "../images/coach-blue.jpg"
import imgCreditCardUser from "../images/credit-card-user.jpg"
import "./index.css";
import Helmet from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="10 second demo" />
        <Helmet>
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />
        </Helmet>
        <div>
            <div>
              <div className='header-container'>
                <img src={imgCoach} alt='Coach' className='hero-image' />
                <div className='header'>
                <Link to="/"><img style={{ maxWidth: `200px`, margin: 0 }} src={imgLogo} /></Link>
                  <div className='nav'>
                    <a href="mailto:info@shuttleid.uk" className='nav-link'>Contact</a>
                    <span className='nav-item'>Sales: 0333 344 9868</span>
                  </div>
                </div>
              </div>

              <div className="content">

                <div className="blog">
                  <div className='headline-block blog-inner'>
                    <h1 className="blog-headline">10 second demo</h1>
                    <p className="subheadline">See how our scanning technology handles shared tickets.</p>
                  </div>
                  <div style={{ marginTop: '50px', textAlign: 'center' }} className='blog-inner'>
                    <div class='embed-container'>
                      <iframe src="https://www.youtube-nocookie.com/embed/2lFEvXpdEKQ?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>

                <div className='block'>
                  <div className='inner' data-aos="zoom-in" data-aos-duration="600">
                    <h2><i style={{ fontSize: '26px' }} className="fas fa-comment-dots"></i> Let's chat</h2>
                    <p>We work closely with coach operators to remove the pain of operating school services.</p>
                    <p>Find out how we could help your business.</p>
                    <p style={{ margin: 0 }}>Contact us at <a style={{ color: '#000' }} href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> or call 0333 344 9868.</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </Layout>
    );
  }
};

export default BlogPage
